import { useFormContext, Controller } from 'react-hook-form'
import { TextField } from '@mui/material'
import { 
  DatePicker,
} from '@mui/lab'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/pt-br'
import dayjs from 'dayjs'

const CustomDatePicker = (props) => { // eslint-disable-line
  const { name, label, type, disabled, numeric } = props
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <Controller
        name={name}
        control={control}
        render={({ field, field: { onChange } }) => (
            <>
                <DatePicker
                    label={label}
                    value={dayjs(field.value)}
                    onChange={value => onChange(value)}
                    renderInput={params =>
                        <TextField
                            label={label}
                            fullWidth={true}
                            {...params}
                            error={!!errors[name]}
                            helperText={errors[name]?.message} />}
                />
            </>
        )}
        />
    </LocalizationProvider>
  )
}

const CustomDateTimePicker = (props) => { // eslint-disable-line
    const { name, label, type, disabled, numeric } = props
    const {
      control,
      formState: { errors },
    } = useFormContext()
  
    const formatValue = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> => {
      if (numeric) {
        e.target.value = e.target.value.replace(/[^\d.]+/g, "")
      }
  
      return e
    }
  
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <Controller
        name={name}
        control={control}
        render={({ field, field: { onChange } }) => (
          <>
            <DateTimePicker
              ampm={false}
              label={label}
              value={dayjs(field.value)}
              onChange={value => onChange(value)}
            />
          </>
        )} />
      </LocalizationProvider>
    )
  }

export { CustomDatePicker, CustomDateTimePicker }
