import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// SERVICES
import {
  getDiscountCouponsById,
  createDiscountCoupons,
  updateDiscountCoupons,
  // getAccountGroups
} from '@services/DiscountCoupons/DiscountCouponsService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateTimePicker from '@mui/lab/DateTimePicker'
import {
  CardHeader,
  CardContent,
  Grid,
  Button,
  Box,
  Card,
  TextField,
  FormControl,
  Typography,
  Switch,
  FormControlLabel,
  MenuItem,
  InputLabel,
  Select,
} from '@mui/material'
import { AddCircleOutlineRounded as AddIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'

//TYPES
// import { AccountGroup } from '@shared/types/DiscountCouponsTypes'
// TODO: Integrar o account group descomentando essas linhas quando a task ST-5040 começar a ser feita.
const DiscountCouponsForm = (): JSX.Element => {
  const [code, setCode] = useState<string>('')
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [percentage, setPercentage] = useState<number>()
  const [isActive, setIsActive] = useState<boolean>(false)
  const [isVerifiedPlayersOnly, setIsVerifiedPlayersOnly] =
    useState<boolean>(false)
  const [isAllowedOncePerMonth, setIsAllowedOncePerMonth] =
    useState<boolean>(false)
  const [targetAudience, setTargetAudience] = useState<string | null>('')
  //const [tagGroupAccounts, setTagGroupAccounts] = useState<AccountGroup[]>([]);
  const [accountGroup, setAccountGroup] = useState<string | null>(null)
  const [maxUsage, setMaxUsage] = useState<string | null>('')

  const navigate = useNavigate()
  const { id } = useParams()
  const formTitleAction = id ? 'Alterar' : 'Cadastrar'
  const buttonLabelAction = id ? 'Salvar' : 'Cadastrar'

  const targetOptions = ['FREE', 'PLUS', 'PREMIUM', 'ALL']
  useEffect(() => {
    if (id) {
      _getDiscountCoupons()
    }
  }, [])

  // useEffect(() => {
  //   if(targetAudience === 'CUSTOM') {
  //     getAccountGroups({
  //       limit: 1999,
  //     }).then((response) => setTagGroupAccounts(response.results))
  //   }
  // }, [targetAudience])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const errors = validateFields()

    if (errors.length) {
      const errorMessages = errors.join('\r\n')
      Alert.error(errorMessages)
      return
    }
    if (id) {
      _updateDiscountCoupons()
    } else {
      _createDiscountCoupons()
    }
  }

  const _createDiscountCoupons = async (): Promise<void> => {
    try {
      const payload = {
        code,
        startDate,
        endDate,
        percentage,
        isVerifiedPlayersOnly,
        isAllowedOncePerMonth,
        targetAudience: targetAudience === '' ? null : targetAudience,
        tagGroupAccounts: accountGroup,
        maxUsage: Number(maxUsage),
      }

      await createDiscountCoupons(payload)

      Alert.success(
        'Cupom de desconto criado com sucesso',
        navigate('/apps/marketplace/discountCoupons/list')
      )
    } catch (err) {
      Alert.error(`${err.errors}`)
    }
  }

  const _updateDiscountCoupons = async (): Promise<void> => {
    try {
      const payload = {
        id,
        code,
        startDate,
        endDate,
        percentage,
        isActive,
        isVerifiedPlayersOnly,
        isAllowedOncePerMonth,
        targetAudience: targetAudience === '' ? null : targetAudience,
        tagGroupAccounts: accountGroup,
        maxUsage: Number(maxUsage),
      }

      await updateDiscountCoupons(payload)

      Alert.success(
        'Cupom de desconto alterado com sucesso',
        navigate('/apps/marketplace/discountCoupons/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao realizar a criação do Cupom de desconto')
    }
  }

  const _getDiscountCoupons = async (): Promise<void> => {
    try {
      const discountCouponsData = await getDiscountCouponsById(id)

      if (discountCouponsData) {
        setCode(discountCouponsData.code || '')
        setStartDate(discountCouponsData.startDate || null)
        setEndDate(discountCouponsData.endDate || null)
        setPercentage(discountCouponsData.percentage || null)
        setIsActive(discountCouponsData.isActive || null)
        setIsVerifiedPlayersOnly(
          discountCouponsData.isVerifiedPlayersOnly || null
        )
        setIsAllowedOncePerMonth(discountCouponsData.isAllowedOncePerMonth || null)
        setTargetAudience(discountCouponsData.targetAudience || null)
        setAccountGroup(discountCouponsData.tagGroupAccounts || null)
        setMaxUsage(discountCouponsData.maxUsage || null)
      }
    } catch {
      Alert.error('Ocorreu um erro ao carregar o Cupom de desconto')
    }
  }

  const validateFields = (): string[] => {
    const errors = []
    if (!code.length) errors.push('O campo Código é obrigatório')
    if (!startDate) errors.push('O campo Data inicial é obrigatório')
    if (!endDate) errors.push('O campo Data final é obrigatório')
    if (!percentage) errors.push('O campo Porcentagem é obrigatório')

    return errors
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title={`${formTitleAction} Cupom de desconto`}>
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  // const renderTagGroupAccountsOptions = (object: any): JSX.Element => {
  //   return (
  //     object &&
  //     object.map((obj) => (
  //       <MenuItem key={obj.id} value={obj.tag}>
  //         {obj.tag}
  //       </MenuItem>
  //     ))
  //   )
  // }

  const renderTargetAudienceOptions = (object: any): JSX.Element => {
    return (
      object &&
      object.map((obj, i) => (
        <MenuItem key={i} value={obj}>
          {obj}
        </MenuItem>
      ))
    )
  }

  return (
    <Card sx={{ width: 600 }}>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{ p: 4 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {!id && (
              <Grid item xs={12}>
                <TextField
                  required
                  label="Cupom de desconto"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setCode(e.target.value)}
                  value={code.toUpperCase()}
                />
                <Typography variant="caption" display="block" gutterBottom>
                  * O nome do cupom não pode ser o mesmo de um que já exista!
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                label="Quantidade de cupons"
                variant="outlined"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                onChange={(e) => setMaxUsage(e.target.value)}
                value={maxUsage}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    inputFormat="dd/LL/yyyy KK:mm aaa"
                    label="Data inicial"
                    value={startDate}
                    onChange={(startDate) => {
                      setStartDate(startDate)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    inputFormat="dd/LL/yyyy KK:mm aaa"
                    label="Data Final"
                    value={endDate}
                    onChange={(finalDate) => {
                      setEndDate(finalDate)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Porcentagem de desconto"
                variant="outlined"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                onChange={(e) => setPercentage(Number(e.target.value))}
                value={percentage}
              />
              <Typography variant="caption" display="block" gutterBottom>
                * Insira apenas números na porcentagem
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 0.2 }}>
            {id && (
              <Grid item xs={5}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isActive}
                      onChange={(e) => setIsActive(e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label={isActive ? 'Cupom ativo' : 'Cupom desativado'}
                />
              </Grid>
            )}
            <Grid item xs={8}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isVerifiedPlayersOnly}
                    onChange={(e) => setIsVerifiedPlayersOnly(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="primary"
                  />
                }
                label="Exclusivo para player verificado"
              />
            </Grid>
            <Grid item xs={8}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isAllowedOncePerMonth}
                    onChange={(e) => setIsAllowedOncePerMonth(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="primary"
                  />
                }
                label="Limite de uso de uma vez por mês"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Público</InputLabel>
              <Select
                required
                label="Público"
                key="targetAudience"
                value={targetAudience}
                onChange={(e) => setTargetAudience(e.target.value)}
              >
                {renderTargetAudienceOptions(targetOptions)}
              </Select>
              <Typography variant="caption" display="block" gutterBottom>
                * Ao selecionar o público CUSTOM, selecione o grupo de usuários.
              </Typography>
            </FormControl>
          </Grid>
          {/* {targetAudience === 'CUSTOM' && (
            <Grid item xs={12} sx={{ mt: 2}}>
            <FormControl fullWidth>
              <InputLabel id="accountGroupsSelect">Grupo de usuários</InputLabel>
              <Select
                required
                label="Grupo de usuários"
                key="accountGroup"
                value={accountGroup}
                onChange={(e) => setAccountGroup(e.target.value)}
              >
                {renderTagGroupAccountsOptions(tagGroupAccounts)}
              </Select>
              </FormControl>
            </Grid>
          )} */}
          <Grid container spacing={2}>
            <Grid item xs sx={{ mt: 3 }}>
              <Box
                display="flex"
                justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
              >
                <Button variant="contained" type="submit">
                  {buttonLabelAction} Cupom de desconto
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export { DiscountCouponsForm }
